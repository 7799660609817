import logo from './logo.svg';
import './App.css';
import Coin from './component/Coin';
import Glitch from './component/glitch';

function App() {
  return (
    <div>
      <Coin />
      {/* <Glitch/>
      <h1>Welcome to the Glitched Experience</h1> */}
    </div>
  );
}

export default App;
